// AuthHandler.ts

import { Api } from '../api/Api';
import { store } from '../store/Reducer';
import { login, getCurrentUser } from '../store/AuthActions';
import { getContent } from '../store/PageContentActions';
import { TOOLBOX_FETCH_REQUESTED, TOOLBOX_RESPONSE_UPDATE, finishedLoading } from '../store/ToolBoxActions';
import { PageType } from '../interfaces/Pages';
import { ATTEMPT_LOGIN } from '../constants/constants';

export class AuthHandler {
  static async handleAuthentication(email: string) {
    try {
      // Dispatch loading action to indicate login attempt
      store.dispatch(finishedLoading(ATTEMPT_LOGIN));

      // Clear any existing auth-related localStorage items
      localStorage.removeItem('authState');
      
      /*
      Object.keys(localStorage).forEach(key => {
        if (key.includes('-b2c_1a_hca_signup_signin') && key.includes('-auth.onekeyconnect.com-')) {
          localStorage.removeItem(key);
        }
      });
      */

      // Verify the user account
      const verificationResponse = await Api.userAccountVerification(email);
      
      if (verificationResponse.message === "HCP_USER_ALLOWED") {
        // User exists, proceed with login
        const loginData = { 
          name: email, 
          pass: 'ycGjol3F0yyB09MUcylmHJ63gDuVXBgxD9gmwlWH3FPWK9In1n' 
        };

        const loginResponse = await Api.login(loginData);

        // Dispatch actions to update store and UI
        store.dispatch(finishedLoading(ATTEMPT_LOGIN, loginResponse, true));
        store.dispatch(login({ ...loginResponse, email }));
        store.dispatch(getContent(PageType.profile));
        store.dispatch({ type: TOOLBOX_FETCH_REQUESTED });
        store.dispatch(getCurrentUser());

        // Note: We're not redirecting here as it should be handled by the component

        return loginResponse;
      } else {
        // Error setting up the user
        throw new Error("User account verification failed");
      }
    } catch (error) {

      // Dispatch error actions
      store.dispatch(finishedLoading(ATTEMPT_LOGIN, error.message, false));
      store.dispatch({ type: TOOLBOX_RESPONSE_UPDATE, error: error.message });

      throw error;
    }
  }
}